<template>
  <div class="payment-successful">
    <a-result
      :status="'success'"
      :title="$t('label.paymentSuccessful')"
      :sub-title="$t('label.thankForPurchase')"
    >
      <template #extra>
        <div>
          <p>{{ $t("label.certificateNo") }}: {{ orderNumber }}</p>
          <p>
            {{ $t("label.totalAmount") }}: ₭
            {{ paymentAmount.toLocaleString() }}
          </p>
        </div>
        <a-button type="primary" @click="viewCertificate" class="mt-30">{{
          $t("button.policySchedule")
        }}</a-button>
        <a-button @click="goToHome">{{ $t("action.BackHomeButton") }}</a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderNumber: "202304-00005",
      paymentAmount: 500000,
    };
  },
  methods: {
    viewCertificate() {
      window.open(
        "https://devapi.laospt.com/policyschedule?id=553&no=202303-000053&member=001",
        "_blank"
      );
    },
    goToHome() {
      // Redirect to the home page or any other desired page
      this.$router.push("/dashboard");
    },
  },
};
</script>

<style scoped>
.payment-successful {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* height: 100vh; */
}
</style>
